.contacto{
    background-color: #ededed;
    width: 100%;
    margin: 200px 0 0;
}

.contacto__fondo{
    display: flex;
    justify-content: center;
    padding-bottom:70px ;
}

.contacto__contenedor{
    background-color:#FFFFFF ;
    width: 1136px;
    color: #333333;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    margin: -112px 0 0;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.contacto__contenedor .contacto__cabecera{
    margin: 0 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contacto__cabecera h3{
    font-size: 30px;
}
.contacto__cabecera hr{
    width: 100%;
    max-width: 70px;
    margin: 32px ;
    border-radius: 6px;
    opacity: 1;
    border-top: 4px solid #094C8A;
}

.contacto__cabecera p{
    font-size: 18px;
}

.contacto__formulario {
    width: 100%;
}

.contacto__formulario .contacto__informacion{
    display: flex;
    width: 100%;
    justify-content: space-between;

    
}

.contacto__informacion .contacto__campo {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    width: 49.5%;
    box-sizing: border-box;
    
}





.contacto__campo input {
    border: 1px solid #ccc;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 13px;
    font-weight: 400;
    color: #1b1b29;
    border-radius: 4px;
    
}

.contacto__formulario select{
    border: 1px solid #ccc;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 13px;
    font-weight: 400;
    color: #1b1b29;
    border-radius: 4px;
}





.contacto__formulario label{
    margin-bottom: 10px;
}

.contacto__formulario span{
    margin-left: 5px;
    color: #094C8A;
}

.contacto__campo__consulta{
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
}



#tipo-consulta{
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 13px;
    font-weight: 400;
    color: #1b1b29;
    border-radius: 4px;
}

#consulta-descripcion{
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 13px;
    font-weight: 400;
    color: #1b1b29;
    border-radius: 4px;
    box-sizing: border-box;
}

.contacto__boton__contenedor{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    
}

.contacto__formulario button{
    border: none;
    font-size: 14px;
    font-weight: 700;
    padding: 16px 34px;
    line-height: 1;
    border-radius: 4px;
    color: #fff;
    background-color: #094C8A;
}



@media screen and (max-width:1150px) {
    .contacto__contenedor{
        width: 96%;
    }
}



@media screen and (max-width:600px) {
    .contacto__formulario .contacto__informacion{
        flex-direction: column;
    }

    .contacto__informacion .contacto__campo{
        width: 99%;
    }
}