.Cuenta{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
}


.Cuenta img{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.Cuenta__informacion{
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(239, 236, 236);
    border-radius: 5px;
    text-align: center;
    margin: 4rem 1rem;
    padding: 2rem 4rem ;
}

.Cuenta__informacion button{
    padding: 16px 34px;
    border: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    border-radius: 4px;
    background-color: #EFEFEF;
    color: #000000;
}


.Cuenta__informacion .Cuenta__boton__editarCuenta:hover{
    background-color: #000000;
    color: #EFEFEF;
}

.Cuenta__informacion .Cuenta__boton__cambiarContraseña{
    margin-top: 1rem;
    color: #094C8A;
}

.Cuenta__informacion .Cuenta__boton__cambiarContraseña:hover{
    background-color: #094C8A;
    color: #EFEFEF;
}

.Cuenta__informacion .Cuenta__boton__eliminarContraseña{
    margin-top: 3rem;
    color: red;
}

.Cuenta__informacion .Cuenta__boton__eliminarContraseña:hover{
    background-color: red;
    color: #EFEFEF;
}

@media screen and (max-width:450px) {
    .Cuenta__informacion{
        padding: 1.5rem 1.5rem ;
    }
}