.FormularioEvento__seccion {
  width: 100%;
  display: flex;
  justify-content: center;
}

.FormularioEvento__formulario {
  border: 2px solid rgb(239, 236, 236);
  margin: 4rem 1rem;
  padding: 2rem 4rem;
  border-radius: 5px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FormularioEvento__formulario h5 {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.FormularioEvento__formulario button {
  width: 100%;
}

.FormularioEvento__modalidadLugarFechaHora {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .FormularioEvento__formulario {
    width: 100%;
  }
}


