.Ubicacion{
    width: 100%;
    display: flex;
    justify-content: center;
}

.Ubicacion__seccion{
    width: 1136px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 80px 0 0;
}

.Ubicacion__domicilio{
    width: 55%;
    display: flex;
    flex-direction: column;
    
}

.Ubicacion__introduccion{

    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Ubicacion__introduccion h2{
    font-family: 'Open Sans';
}

.Ubicacion__introduccion p{
    font-size: 18px;
}

.Ubicacion__mapaDomicilio{
    display: flex;
    gap: 24px;
}

.Ubicacion__mapaDomicilio img{
    border-radius: 5px;
}

.Ubicacion__mapaDomicilio__Domicilio h3{
    font-size: 17px;
}



.Ubicacion__sedes{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding-top: 8px;
}

.Ubicacion__sedes h4{
    font-size: 18px;
}

@media screen and (max-width:1136px) {
    
    .Ubicacion__seccion{
        width: 96%;
    }
}


@media screen and (max-width:999px) {
    
    .Ubicacion__seccion{
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .Ubicacion__domicilio{
        width: 100%;
    }

    .Ubicacion__sedes{
        width: 100%;
    }
}

@media screen and (max-width:700px) {
    
    .Ubicacion__mapaDomicilio{
        flex-direction: column;
    }
}