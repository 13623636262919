.Seccion1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Seccion1__contenedor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 80px 0;
  width: 1140px;
}

.Seccion1__informacion {
  width: 570px;
  text-align: justify;
  padding: 0 12px;
}

.Seccion1__informacion h2 {
  font-family: "Bebas Neue";
}

.Seccion1__informacion p {
  line-height: 1.8;
}

.Seccion1__informacion p:first-child {
  font-weight: 600;
  color: #094c8a;
}

.Seccion1__boton {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.Seccion1__boton a {
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: #094c8a;
  background: #094c8a;
  color: white;
  text-decoration: none;
}

.Seccion1__imagen {
  width: 570px;
  display: block;
}

.Seccion1__imagen img {
  float: right;
  height: 278px;
  border-radius: 0.375rem;
}

@media screen and (max-width: 1400px) {
  .Seccion1__contenedor {
    width: 100%;
    padding: 0 12px;
  }

  .Seccion1__informacion {
    width: 50%;
  }

  .Seccion1__imagen {
    width: 50%;
  }
}


@media screen and (max-width: 1000px) {

    .Seccion1__informacion {
        width: 100%;
      }

    .Seccion1__imagen {
        display: none;
      }
}