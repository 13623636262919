.Seccion5{

    background-position: right -200px;
    background-image: url(../../../assets/img/mapa_mundial.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;

    
    display: flex;
   
    justify-content: center;
    padding: 80px 0 ;

   
}

.Seccion5__seccion{
    width: 1160px;
    height: 100%;
    line-height: 1.8;
    display: flex;
    
}



.Seccion5__informacion{
    width: 65%;
    margin-left: 15%;

    text-align: justify;
}

.Seccion5__informacion p:first-child{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #094C8A;
}

.Seccion5__informacion h2{
    font-size: 32px;
    margin-bottom: 24px;
}




.Seccion5__informacion p{
    margin-bottom: 24px;
    font-size: 16px;
}



@media screen and (max-width:1400px) {
    
    .Seccion5 {
        background-position: right -100px;
    }
}




@media screen and (max-width:700px) {
    
    .Seccion5 {
        background-position: center ;
    }

    .Seccion5__seccion{
        justify-content: center;
    }

    .Seccion5__informacion{
        width: 90%;
        margin: 0;
    }
}