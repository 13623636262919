@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&family=Open+Sans:wght@300;400;600;700&display=swap');




*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat";
}

h2, h5{
    font-family: "Bebas Neue";
}