.carrusel {
  z-index: 0;
}

.carrusel__imagenes {
  width: 100%;
  height: 550px;
  object-fit: cover;
  filter: brightness(50%);
  z-index: 0;
}

.carrusel__imagenes__positionTop{
  width: 100%;
  height: 550px;
  object-fit: cover;  
  object-position:top;
  filter: brightness(50%);
  z-index: 0;
}

.carrusel__informacion {
  max-width: 100%;
  height: 100%;
  right: 18%;
  left: 12%;
  padding: 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.carrusel__contenedor {
  max-width: 80%;
  height: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.carrusel__contenedor h2 {
  color: #fff;
  font-size: 52px;
  line-height: 62px;
  margin-bottom: 20px;
  font-weight: 300;
}

.carrusel__contenedor p {
  color: #fff;
  padding-bottom: 16px;
}

.carrusel__contenedor a {
  
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: white;
  background: white;
  color: black;
  text-decoration: none;
}


@media screen and (max-width: 767px) {
  .carrusel__imagenes {
    height: 350px;
  }
  .carrusel__contenedor h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .carrusel__contenedor p {
    font-size: 12px;
  }


  .carrusel__contenedor button {

    padding: 12px 25px;
    font-size: 10px;
  }
}
