.Usuarios{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Usuarios__titulo__boton{
    width: 1140px;
    margin: 80px 0 0;

    display: flex;
    justify-content: space-between;
}

.Usuarios__botonRegistrar{
    padding: 16px 34px;
    border: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    border-radius: 4px;
    border-color: #094C8A;
    background: #094C8A;
    color: white;
}



.Usuarios__tabla{
    width: 1140px;
    margin: 20px 0 80px;
    border: 1px solid #ddd;
}

.Usuarios__tabla thead{
    border: 1px solid #ddd;
}

.Usuarios__tabla th{
    padding: 8px;
}

@media screen and (max-width:1200px) {

    .Usuarios{
        padding: 0 12px;
    }

    .Usuarios__titulo__boton{
        width: 100%;
    }

    .Usuarios__tabla{
        width: 100%;
    }
}

@media screen and (max-width:500px) {

    .Usuarios__titulo__boton{
        flex-direction: column;
        align-items: center;
    }

    .Usuarios__tabla thead{
        display: flex;
        flex-direction: column;
    }
}
