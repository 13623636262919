.Post {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Post__seccion {
  max-width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: column;
 
}

.Post__publicacion {
  width: 100%;
  display: flex;
  padding: 2%;

  gap: 20px;
  margin-bottom: 30px;
}

.Post__imagen{
  width: 50%;
}

.Post__imagen img{
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.Post__textos{
  width: 50%;
}

.Post__textos  h2{
  margin:0;
    font-size: 3rem;
}

.Post__textos a  {
  
  text-decoration: none;
  color: inherit;
}

.Post__info{
  margin:24px 0;
    color: #888;
    font-size:12px;
    font-weight: bold;
    display: flex;
    gap: 10px;
    
}

.Post__info .Post__autor{
  color:#333;
}

.Post__resumen{
  margin:10px 0;
    line-height: 1.8rem;
    text-align: justify;
}


@media screen and (max-width:1400px) {
  .Post__publicacion{
    flex-direction: column-reverse;
    align-items: center;
  }

  .Post__imagen{
    width: 600px;
  }

  .Post__textos{
    width: 100%;
  }

  .Post__textos h2{
    text-align: center;
  }
}

@media screen and (max-width:600px){
  .Post__imagen{
    width: 100%;
  }
}