
.TituloPrincipal{
    display: flex;
    justify-content: center;
    padding: 48px 0;
}

.TituloPrincipal__contenedor{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.TituloPrincipal__contenedor p{
    text-align: justify;
    line-height: 1.8;
}


.TituloPrincipal__lineaDivisora {
    margin: 10px;
    width: 50%;
    height: 3px;
    background-color: #094c8a;
    border: 1px solid #094c8a;
}


@media screen and (max-width:1000px){
    .TituloPrincipal__contenedor{
        width: 90%;
    }
}