.Seccion3 {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  height: 100%;
  background: #ededed;
}

.Seccion3__contenedor {
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  line-height: 1.8;
  height: 100%;
}

.Seccion3__informacion__imagen{
    width: 100%;
    display: flex;
    text-align: justify;
}

.Seccion3__informacion{
  width: 70%;
  margin-right: 2rem;
}

.Seccion3__informacion h1{
  font-size: 35px;
}

.Seccion3__informacion h3{
  font-size: 30px;
}

.Seccion3__informacion p:first-of-type, h3{
    margin-bottom: 2rem;
}

.Seccion3__imagen{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Seccion3__imagen{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}



#imagenOpensource{
  
    width: 260px;
    height: 90px;
}


@media screen and (max-width: 1200px) {

  

  .Seccion3__informacion__imagen{
    flex-direction: column;
    align-items: center;
    
  }

  .Seccion3__informacion{
    margin-bottom: 0;
  }

  .Seccion3__imagen{
    width: 100%;
    margin-bottom: 24px;
  }
}


@media screen and (max-width:768px) {
  .Seccion3__informacion h1{
    font-size: 30px;
  }
  
  .Seccion3__informacion h3{
    font-size: 25px;
  }
}