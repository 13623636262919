.LandingPageEvento__btn {
  width: 100%;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  padding: 32px 0;
  color: white;
  
}

.LandingPageEvento__btn a {
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: #094c8a;
  background: #094c8a;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
