.Contactos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Contactos__contenedor{
    width: 1400px;
    margin: 80px 0 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Contactos__titulo{
    display: flex;
    justify-content: space-between;
}

.Contactos__selectCategorias{
    display: flex;
    align-items: center;
    gap: 12px;
}

.Contactos__tabla{
    width: 1400px;
    margin: 20px 0 80px;
    border: 1px solid #ddd;
}


.Contactos__tabla thead{
    border: 1px solid #ddd;
}

.Contactos__tabla th{
    padding: 8px;
}

.Contactos__tabla__renglon td{
    padding: 8px;
    border: 1px solid #ddd;
}

.Contactos__tabla__renglon{
    border-bottom-width: 1px;
    background-color: rgba(0,0,0,0.05);
}


.Contactos__selectCategorias button{
    border: 1px solid #ccc;
    padding: 10px 20px;
    box-shadow: none;
    font-size: 13px;
    font-weight: 400;
    color: #1b1b29;
    border-radius: 4px;
    background: #fff;
}




@media screen and (max-width:1400px) {
    .Contactos__contenedor{
        width: 98%;
    }
}


@media screen and (max-width:1200px) {

    .Contactos{
        padding: 0 12px;
    }

    .Contactos__titulo{
        flex-direction: column;
        align-items: center;
    }

    .Contactos__tabla{
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .Contactos__tabla thead{
        display: flex;
        flex-direction: column;
    }
}
