.seccion8 {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
}

.seccion8__seccion {
  width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.seccion8__textoImagen {
  width: 98%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2%;
}

.seccion8__textoImagen__informacion {
  width: 50%;
  text-align: justify;
  line-height: 1.8;
}

.seccion8__textoImagen__informacion p:first-child {
    font-weight: 600;
    color: #094c8a;
  }
  
.seccion8__textoImagen__informacion h2 {
  margin-bottom: 24px;
}

.seccion8__textoImagen__imagen {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.seccion8__textoImagen__imagen img {
  width: 80%;
}

.seccion8__imagenTexto {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.seccion8__imagenTexto__imagen {
  width: 50%;
  display: flex;
  justify-content: center;
}

.seccion8__imagenTexto__imagen img {
  width: 80%;
}

.seccion8__imagenTexto__informacion {
  width: 40%;
  display: flex;
  align-items: center;
  text-align: justify;
  line-height: 1.8;
}

@media screen and (max-width: 1400px) {
  .seccion8__seccion {
    width: 100%;
  }

  .seccion8__textoImagen {
    flex-direction: column;
    align-items: center;
  }

  .seccion8__textoImagen__informacion {
    width: 95%;
  }

  

  .seccion8__textoImagen__imagen {
    width: 90%;
    justify-content: center;
    margin: 32px 0;
  }

  .seccion8__textoImagen__imagen img {
    width: 60%;
  }

  .seccion8__imagenTexto {
    flex-direction: column-reverse;
    align-items: center;
  }

  .seccion8__imagenTexto__imagen {
    width: 90%;
    margin: 32px 0;
  }

  .seccion8__imagenTexto__imagen img {
    width: 60%;
  }

  .seccion8__imagenTexto__informacion {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  

  .seccion8__textoImagen__imagen img {
    width: 80%;
  }

  

  .seccion8__imagenTexto__imagen img {
    width: 80%;
  }

  
}

