.faq__wrapper{
    margin-top: 3%;
    margin: 112px 0;
}

.faq__container .faq__information{
    width: 100%;
    display: flex;
    justify-content: center;
}
.faq__container .faq__information div{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;   
    text-align: center;  
}

.faq__information h2{
    font-weight: 300;
    font-size: 32px;
    color: #212529;
    margin: 0 0 24px;
    line-height: 1.3;   
}

.faq__information hr{
    width: 100%;
    max-width: 70px;
    border-top: 4px solid #094C8A;
    border-radius: 6px;
    opacity: 1;
    margin: 20px 0;
}

.faq__information p{
    font-size: 18px;    
}


.faq__accordion{
    
    width: 100%;
    max-width: 700px;
    margin: 18px auto;   
}

.faq__item{
    background: #ffffff;

    
    margin-bottom: 5px;
    padding: 0px 0px;
     
}
.faq__title h2{
    font-size: 18px;
}

.faq__title span{
    padding-bottom: 1%;
    padding-top: 1%;
}

.faq__title{
    height: 80px;
     border-radius: 12px;  
    margin-bottom: 5px;
    padding: 10px 20px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    padding-bottom: 1%;
    padding-top: 2%;
    
    background-color: #212529;
    -webkit-transition: background-color 1s linear;
    transition: background-color 1s linear;
}
.faq__title:hover{
    background-color: #3F51B5;
}

.faq__content{
    background: #ffffff;
    color: #292929;
    padding-left: 7%;
    padding-right: 7%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1); 
    
}

.faq__content.show{
    background: #ffffff;
    color: #292929;
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);

    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 5%;
    padding-top: 4%;
}


@media screen and (max-width:800px) {
    .faq__container{
        padding: 0 12px;
    }

    .faq__accordion{
        padding: 0 12px;
    }
}