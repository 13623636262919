.PieDePagina {
  width: 100%;
  height: 100%;
  background-color: #094C8A;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}


.PieDePagina__primeraSeccion {
    width: 1160px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 0 ;
}


.PieDePagina__primeraSeccion__logoIdiomas__paginas{
    width: 75%;
    display: flex;
}

/*Seccion de Logo y boton con idiomas*/

.PieDePagina__primeraSeccion__logoIdiomas{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.PieDePagina__primeraSeccion__logoIdiomas img{
    width: 220px;
    height: 70px;
}

.PieDePagina__primeraSeccion__logoIdiomas button{
    border: 1px solid #ccc;
    font-size: 14px;
    font-weight: 700;
    padding: 16px 34px;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 50px;
    margin-left: 30px;
}




/*Seccion de Paginas*/


.PieDePagina__primeraSeccion__paginas{
    width: 100%;
    display: flex;
    justify-content: space-around;
}


.PieDePagina__primeraSeccion__paginas h6{
    font-weight: 600;
    font-size: 1.200rem;
    margin-bottom: 1rem;
}

.PieDePagina__primeraSeccion__paginas ul{
    list-style: none;
    padding-left:0;
    
}

.PieDePagina__primeraSeccion__paginas li, .PieDePagina__primeraSeccion__paginas a{
    text-decoration: none;
    padding-left:0;
    color: white;
    line-height: 1.75;
    
}


/*Seccion de Contacto*/

.PieDePagina__primeraSeccion__contacto{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.PieDePagina__primeraSeccion__contacto h6{
    font-weight: 600;
    font-size: 1.200rem;
}

.PieDePagina__primeraSeccion__contacto div{
    display: flex;
    line-height: 1.7;
    margin-top: 30px;
}

.PieDePagina__primeraSeccion__contacto .correo{
    margin-right: 20px;
    width: 30px;
    height: 30px;
    color: white;
}

.PieDePagina__primeraSeccion__contacto .redesSociales{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.PieDePagina__primeraSeccion__contacto .redesSociales .icon{
    width: 30px;
    height: 30px;
    color: white;
}





/*Seccion de HR*/






.PieDePagina hr{
    
    width: 100%;
    z-index: 9999;
    
}





.PieDePagina__segundaSeccion{
    width: 1160px;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
}

.PieDePagina__segundaSeccion__secciones{
    display: flex;
    flex-direction: row;
    
}

.PieDePagina__segundaSeccion__secciones{
    gap: 1.5rem;
}

.PieDePagina__segundaSeccion__secciones a{
    text-decoration: none;
    color: white;
}


@media screen and (max-width: 1300px) {

    .PieDePagina__primeraSeccion{
        width: 100%;
    }

    .PieDePagina__segundaSeccion{
        width: 95%;
    }

    .PieDePagina__primeraSeccion__logoIdiomas__paginas{
        margin-bottom: 24px;
    }
    
}

@media screen and (max-width: 1000px) {

    .PieDePagina__primeraSeccion{
        
        flex-direction: column;
        align-items: center;
    }

    .PieDePagina__primeraSeccion__logoIdiomas__paginas{
        width: 100%;
    }

    .PieDePagina__primeraSeccion__contacto{
        justify-content: center;
    }
    
    .PieDePagina__segundaSeccion{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
.PieDePagina__primeraSeccion__paginas{
    flex-direction: column;
    align-items: center;
}


}