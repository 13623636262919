.seccion9 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ededed;
}

.seccion9__contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  width: 1140px;
}

.seccion9__imagen{
  display: flex;
  justify-content: center;
}

.seccion9__imagen img {
  float: right;
  height: 278px;
  border-radius: 0.375rem;
}



.seccion9__texto{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seccion9__texto p{
  line-height: 1.8;
}


.seccion9__lineaDivisora {
  margin: 10px;
  width: 50%;
  height: 3px;
  background-color: #094c8a;
  border: 1px solid #094c8a;
}




.seccion9__imagen a img {
  color: #262626;
  transition: .5s;
  z-index: 3;
}

.seccion9__imagen a:hover img {
  
  transform: rotateY(360deg);
}


@media screen and (max-width: 1400px) {

  .seccion9__contenedor {
    width: 100%;
    padding: 0 12px;
  }

}