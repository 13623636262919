.Seccion__EventoInformacion{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 64px;
}

.Seccion__EventoInformacion__contenedor{
    width: 1400px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.Seccion__EventoInformacion__cartel{
    width: 50%;
    display: flex;
    justify-content: center;
}

.Seccion__EventoInformacion__cartel img{
    width: 600px;

}

.Seccion__EventoInformacion__informacion{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Seccion__EventoInformacion__informacion p{
    text-align: justify;
    line-height: 1.8;
}


@media screen and (max-width:1400px) {
     .Seccion__EventoInformacion__contenedor{
        width: 90%;
        flex-direction: column;
        align-items: center;
     }   

     .Seccion__EventoInformacion__informacion{
        width: 100%;
     }
}


@media screen and (max-width:600px) {

    .Seccion__EventoInformacion__cartel{

        width: 95%;
    }

    .Seccion__EventoInformacion__cartel img{
        width: 100%;
    }
}