.Publicaciones__seccion{
    width: 100%;
    display: flex;
    justify-content: center;
}

.Publicaciones__formulario{
    border: 2px solid rgb(239, 236, 236);
    margin: 4rem 1rem;
    padding: 2rem 4rem ;
    border-radius: 5px;
    width: 1200px;
    
}

.Publicaciones__formulario h5{
    font-size: 35px;
    font-weight: 500;
    text-align: center;
}

.Publicaciones__formulario button{
    width: 100%;
}



@media screen and (max-width: 1200px){
    .Publicaciones__formulario{
        
    width: 100%;
    }
}