.miniBarraNavegacion{
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    background: #dfdede;
    position: fixed;
    z-index: 2;
}

.miniBarraNavegacion__contenedor{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.miniBarraNavegacion__elementos{
    height: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
}

.miniBarraNavegacion__idiomas{
  background: transparent;
  border: none;
  color: rgba(0,0,0,.5);
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin: 0 12px;
}

.miniBarraNavegacion__idiomas:hover{
  color: #000000;
}

.miniBarraNavegacion__idiomas option{
  padding: 0 12px;
    line-height: 30px;
    background-color: #dfdede;;
    font-size: 12px;
    font-weight: 400;
}

.miniBarraNavegacion__idiomas option:hover{
  color: #000000;
}

.miniBarraNavegacion__elementos li a{
    padding: 0 12px;
    line-height: 30px;
    color: rgba(0,0,0,.5);
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}

.miniBarraNavegacion__elementos li a:hover{
  color: #000000;
}

.miniBarraNavegacion__elementos li button{
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 0 12px;
    line-height: 30px;
    color: rgba(0,0,0,.5);
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}


.miniBarraNavegacion__label{
    color: #fff;
}


.miniBarraNavegacion__label,
.miniBarraNavegacion__input {
  display: none;
}


@media screen and (max-width: 1150px) {
    .miniBarraNavegacion__label {
        display: initial;
       cursor: pointer;
      }
    
      .miniBarraNavegacion{
        position: relative;
        position: fixed;
      }
    
      .miniBarraNavegacion__lista{
        position: absolute;
    
        height: auto;
    
        top: 100%;
        left: 0;
        right: 0;
        background: #dfdede;
        border-top: 1px solid rgba(0, 0, 0, .1);
        display: none;
        color: #000000;
        z-index: 2;
      }
    
      .miniBarraNavegacion__elementos {
        width: 95%;
        height: 100%;
        gap: 1em;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px 0;
      }
     
    
    
      #miniBarraNavegacion__checkbox:checked ~ .miniBarraNavegacion__lista{
        display: initial;
      }
    
      
    
  }
  