.Login__fondo{
    padding: 100px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    
}

.overlay.close{
    display: none;
}



.Login__contenedor{
    width: 820px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 50px -10px rgb(0 ,0 ,0 , 90%);
    
    display: flex;
}



.Login__izquierda{
    width: 35%;
    height: auto;
    background-image: url("../../../assets/img/modal-popup.jpeg");
    background-size: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
}

.Login__derecha{
    width: 65%;
    height: 100%;
    padding: 24px;
   
}

.Login__derecha h3{
    color: #3f3f3f;
    font-size: 26px;
    font-weight: 400;
    margin: 24px 0;
}



.Login__derecha__formulario{
    width: 100%;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
    justify-content: flex-end;
}

.Login__derecha__formulario__campo{
    padding: 0 5px;
    width: 100%;
    margin-bottom: 16px;
}

.Login__derecha__formulario__campo label{
    font-weight: 500;
    margin-bottom: 10px;
}

.Login__derecha__formulario__campo span{
    color: #e80000;
    margin-left: 5px;

}

.Login__derecha__formulario__campo input{
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 4px;
}




.Login__derecha__boton__cerrar{
    float: right;
    width: 20px;
    height: 20px;
    font-weight: 800;
    cursor: pointer;
}

.Login__derecha__formulario__boton{
    margin: 20px 0;
    
}

.Login__derecha__formulario__boton button{
    background-color: #094C8A;
    border-width: 0px;
    color: #ffffff;
    padding: 14px 40px;
    border-radius: 4px;
    font-weight: bold;

    
}


@media screen and (max-width:900px) {
    .Login__contenedor{
        margin: 0 12px;
    }

    .Login__izquierda{
        display: none;
    }

    .Login__derecha{
        width: 100%;
    }
}
