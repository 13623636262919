.Seccion4{

    
    width: 100%;
    display: flex;
   
    justify-content: center;
    padding: 80px 0 ;

   
}

.Seccion4__seccion{
    width: 1160px;
    line-height: 1.8;
    display: flex;
    
}

.Seccion4__imagen{
    width: 35%;
    display: flex;
    align-items: center;
}

.Seccion4__imagen img{
    width: 460px;
    height: 460px;
    border-radius: 6px;
}

.Seccion4__informacion{
    width: 65%;
    padding-left: 15%;
}

.Seccion4__informacion p{
    text-align: justify;
}

.Seccion4__informacion p:first-child{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #094C8A;
}

.Seccion4__informacion h2{
    font-size: 32px;
    margin-bottom: 24px;
}




.Seccion4__informacion p{
    margin-bottom: 24px;
    font-size: 16px;
}


@media screen  and (max-width:1200px){
    .Seccion4__seccion{
        width: 90%;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    .Seccion4__imagen{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .Seccion4__imagen img{
        width: 80%;
        height: auto;
    }

    .Seccion4__informacion{
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    
}


