.Seccion7 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Seccion7__contenedor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 80px 0;
  width: 1140px;
}

.Seccion7__informacion {
  width: 570px;
  text-align: justify;
  padding: 0 12px;
}

.Seccion7__informacion h2 {
  font-family: "Bebas Neue";
}

.Seccion7__informacion p {
  line-height: 1.8;
}

.Seccion7__informacion p:first-child {
  font-weight: 600;
  color: #094c8a;
}



.Seccion7__imagen {
  width: 570px;
  display: block;
}

.Seccion7__imagen img {
  float: right;
  width: 85%;
  height: auto;
  border-radius: 0.375rem;
}

@media screen and (max-width: 1400px) {
  .Seccion7__contenedor {
    width: 100%;
    padding: 0 12px;
  }

  .Seccion7__informacion {
    width: 50%;
  }

  .Seccion7__imagen {
    width: 50%;
  }
}


@media screen and (max-width: 1000px) {

    .Seccion7__informacion {
        width: 100%;
      }

    .Seccion7__imagen {
        display: none;
      }
}