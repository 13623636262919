

.Usuario__datos td{
    padding: 8px;
    border: 1px solid #ddd;
}

.Usuario__datos{
    border-bottom-width: 1px;
    background-color: rgba(0,0,0,0.05);
}

#icono__Editar{
    width: 20px;
    height: 20px;
    color: green;
    cursor: pointer;
}

#icono__Basura{
    width: 20px;
    height: 20px;
    color: red;
    cursor: pointer;
}

@media screen and (max-width:500px) {
    .Usuario__datos{
        display: flex;
        flex-direction: column;
    }
}

