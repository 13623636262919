.Seccion2 {
  width: 100%;
  height: 580px;
  background-color: #ededed;
  background-position: -300px;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Montserrat";
}

.Seccion2__gradiante {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, #ebebeb 50%);
}

.Seccion2__informacion {
  padding: 50px 30px 0 0;
  float: right;
  width: 45%;
  text-align: justify;
}

.Seccion2__informacion h5 {
  padding-bottom: 16px;
  font-size: 35px;
}

.Seccion2__informacion p {
  font-size: 16px;
}

#Seccion2__primerParrafo {
  font-size: 20px;
  font-weight: 400;
}

@media screen and (max-width: 1500px) {
  .Seccion2 {
    background-position: 0;
  }

  .Seccion2__informacion {
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  
  

  .Seccion2__gradiante {
    background: linear-gradient(to bottom, transparent, #ebebeb 80%);

    background-size: cover;
  }
}

@media screen and (max-width: 700px) {
  .Seccion2 {
    height: 1200px;
    background-position: -600px;
  }
}
