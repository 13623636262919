.Seccion__EventoAgenda{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ededed;
    padding: 50px 0;
}

.Seccion__EventoAgenda__contenedor{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    background-color: #ededed;
}

.Seccion__EventoAgenda__titulo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 25%;
}

.Seccion__EventoAgenda__titulo span{
    font-size: 70px;
}

.Seccion__EventoAgenda__titulo h2{
    font-family: 'Montserrat';
}

.Seccion__EventoAgenda__lineaDivisora{
    bottom: 0;
}


.Seccion__EventoAgenda__lineaDivisora {
    
    width: 311px;
    height: 3px;
    border: 5px;
    background-color: #094c8a;
}


.Seccion__EventoAgenda__agenda{
    width: 100%;
}

.Seccion__EventoAgenda__agenda table{
    width: 100%;
    border:2px solid #ddd;
}

.Seccion__EventoAgenda__agenda table th {
    border:2px solid #ddd;  
    padding: 8px;
}

.Seccion__EventoAgenda__agenda table td {
    border:2px solid #ddd;  
    padding: 8px;
}

.Seccion__EventoAgenda__agenda table #hora  {
    border:2px solid #ddd;  
    padding: 8px;
    color: #094c8a;
    font-weight: 600;
}

.Seccion__EventoAgenda__agenda table #localizacion  {
    border:2px solid #ddd;  
    padding: 8px;
}

.Seccion__EventoAgenda__agenda table #contenido  {
    font-weight: 800;
}


@media screen and (max-width:750px) {

    .Seccion__EventoAgenda__titulo{
        flex-direction: column;
        align-items: center;
    }

    .Seccion__EventoAgenda__titulo span{
        font-size: 35px;
    }
    .Seccion__EventoAgenda__contenedor{
        width: 98%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
    }

    .Seccion__EventoAgenda__agenda{
        font-size: 12px;
    }
}


@media screen and (max-width:550px) {

    .Seccion__EventoAgenda__agenda{
        font-size: 7px;
    }
}