.PublicacionCompleta__fondo__titulo {
  background: rgba(33, 37, 41);
  position: relative;
  width: 100%;
  height: 333px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PublicacionCompleta__fondo__titulo img {
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 100%;

  z-index: 0;
  filter: brightness(50%) opacity(70%);
}

.PublicacionCompleta__fondo__titulo__informacion {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PublicacionCompleta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.PublicacionCompleta__contenedor {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0;
}

.PublicacionCompleta__contenido {
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.PublicacionCompleta__contenido p {
  margin: 0;
}

.PublicacionCompleta__fondo__titulo h1 {
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
}

.PublicacionCompleta__fondo__titulo time {
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
}

.PublicacionCompleta__botones {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  font: 0/0 a;
  text-shadow: transparent;
  color: transparent;
  text-decoration: none;
}

.PublicacionCompleta__botones__btnEditar {
  background-color: #333;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.PublicacionCompleta__botones__btnEditar svg {
  height: 20px;
}

.PublicacionCompleta__botones__btnEliminar {
  background-color: #cc0606;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  border: none;
}

.PublicacionCompleta__botones__btnEliminar img {
  height: 20px;
}

@media screen and (max-width: 750px) {
  .PublicacionCompleta__contenedor {
    width: 90%;
  }

  .PublicacionCompleta__botones {
    flex-direction: column;
    align-items: center;
    font: 0/0 a;
  text-shadow: transparent;
  color: transparent;
  text-decoration: none;
  }

  .PublicacionCompleta__botones__btnEditar {
    font-size: 12px;
    padding: 15px;
  }

  .PublicacionCompleta__botones__btnEliminar {
    font-size: 12px;
    padding: 15px;
  }
}
