.TarjetaDeMiembro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.TarjetaMiembro__contenedor{
    width: 70%;
    height: 400px;
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
}

.TarjetaDeMiembro__img{
    display: flex;
    justify-content: center;
    width: 40%;
} 


.TarjetaDeMiembro__img img{
    width: auto;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #f3f1f1;
}

.TarjetaDeMiembro__informacion{
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
}
.TarjetaDeMiembro__informacion h3{
    font-weight: 400;
}

.TarjetaDeMiembro__informacion h4{
    color: #094C8A;
    font-weight: 600;
}

.TarjetaDeMiembro__informacion p{
    line-height: 1.8;
    text-align: justify;
}

.TarjetaDeMiembro__redesSociales{
    display: flex;
    gap: 15px;
}

.TarjetaDeMiembro__redesSociales .icon{
    width: 30px;
    height: 30px;
}


@media screen and (max-width:1200px) {
    .TarjetaMiembro__contenedor{
        width: 80%;
        justify-content: space-between;
    }
}

@media screen and (max-width:1000px) {
    .TarjetaMiembro__contenedor{
        width: 90%;
        justify-content: space-between;
    }
}

@media screen and (max-width:800px) {
    .TarjetaMiembro__contenedor{
        flex-direction: column;
        height: 100%;
        margin: 36px 0;
        gap: 32px;
    }

    .TarjetaDeMiembro__img{
        width: 100%;
    }

    
.TarjetaDeMiembro__img img{
    width: 300px;
    height: 400px;
}

    .TarjetaDeMiembro__informacion{
        width: 100%;
    }
}