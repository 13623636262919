.seccion6{
    background: #ededed;
    width: 100%;
    padding: 80px 0;
   display: flex;
   justify-content: center;
}

.seccion6__seccion{
    width: 1160px;
    height: 100%;
    display: flex;
    flex-direction: column;
}




.seccion6__textoImagen{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.seccion6__textoImagen__informacion{
    width: 50%;
    text-align: justify;
}
.seccion6__textoImagen__informacion h2{
margin-bottom: 24px;
}



.seccion6__textoImagen__imagen{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}




.seccion6__textoImagen__imagen img{
    width: 420px;
    height: 450px;
}



.seccion6__imagenTexto{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.seccion6__imagenTexto__imagen{
    width: 50%;
    display: flex;
    justify-content: center;
    
}

.seccion6__imagenTexto__imagen img{
    width: 420px;
    height: 450px;
}

.seccion6__imagenTexto__informacion{
    width: 40%;
    display: flex;
    align-items: center;
    text-align: justify;
}

@media screen and (max-width:1000px){


    .seccion6__seccion{
        width: 100%;
    }

    

    .seccion6__textoImagen{
        flex-direction: column;
        align-items: center;
        
    }

    .seccion6__textoImagen__informacion{
        width: 90%;
    }

    .seccion6__textoImagen__imagen{
        justify-content: center;
        margin: 32px 0 ;
    }

    .seccion6__textoImagen__imagen img{
        width: 100%;
        height: 100%;
    }

    .seccion6__imagenTexto{
        flex-direction: column-reverse;
        align-items: center;
    }

    .seccion6__imagenTexto__imagen{
        margin: 32px 0 ;
    }

    .seccion6__imagenTexto__imagen img {
        width: 100%;
        height: 100%;
    }

    .seccion6__imagenTexto__informacion{
        width: 90%;
    }
}