.SeccionUniversidad {
  background-color: #ededed;
  padding: 80px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SeccionUniversidad__informacion {
  width: 1160px;
  height: 150px;
  display: flex;
  justify-content: center;
}

.SeccionUniversidad__informacion div {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.SeccionUniversidad__informacion h2 {
  font-weight: 300;
  font-size: 32px;
  color: #212529;
  margin: 0 0 24px;
  line-height: 1.3;
}

.SeccionUniversidad__informacion hr {
  width: 100%;
  max-width: 70px;
  border-top: 4px solid #094c8a;
  border-radius: 6px;
  opacity: 1;
  margin: 32px 0;
}

.SeccionUniversidad__universidades {
  width: 1160px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin: 40px 0;
}

.SeccionUniversidad__universidad {
  width: 348px;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 22px 22px 0 rgb(11 37 75 / 1%);
  transition: all 0.3s ease-in-out;
}

.SeccionUniversidad__universidad:hover {
  transform: scale(1.06);
}

.SeccionUniversidad__universidad__imagen-titulo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.SeccionUniversidad__universidad__imagen-titulo img {
  
  width: 80%;
  height: 55%;
}

.SeccionUniversidad__universidad__imagen-titulo h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  margin: 0 0 24px;
  line-height: 1.3;
}

.SeccionUniversidad__universidad a p {
  font-size: 15px;
  line-height: 1.8;
  color: #000000;
}

.SeccionUniversidad__boton__contenedor {
  width: 100%;
  display: flex;
  justify-content: center;
}

.SeccionUniversidad__boton__contenedor a {
  font-size: 14px;
  font-weight: 700;
  padding: 16px 34px;
  line-height: 1;
  border-radius: 4px;
  border: none;
  background: #094c8a;
  color: #fff;
  text-decoration: none;
}

/*Logos*/

#TM{
  height: 65%;
}

#UTR{
  width: 85%;
}

#UTNA{
  width: 70%;
}

#UPA{
  width: 90%;
}

#UT{
  width: 100%;
}

#UTM{
  width: 70%;
}

#IEST{
  width: 100%;
}

#TS{
  width: 100%;
}

#ITP{
  width: 100%;
}

#ITZ{
  width: 100%;
}

#UPZ{
  width: 100%;
}

#ITSNa{
  width: 100%;
}

#ITEL{
  width: 70%;
}

#UTN{
  width: 100%;
}

#ITDZ{
  width: 100%;
}

#UAZ{
  width: 40%;
}

#ITSSAT{
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .SeccionUniversidad__informacion {
    width: 100%;
  }

  .SeccionUniversidad__universidades {
    width: 100%;
  }
}

/* @media screen and (max-width: 1000px) {
  .SeccionUniversidad__universidad {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .SeccionUniversidad__universidad {
    width: 95%;
  }
} */
