.Seccion__EventoOradores {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 64px;
}

.Seccion__EventoOradores__contenedor {
  width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Seccion__EventoOradores__contenedor__oradorIzquierda {
  display: flex;
}

.Seccion__EventoOradores__contenedor__oradorDerecha {
  display: flex;
  justify-content: flex-end;
}

.Seccion__EventoOradores__orador__imagenTexto {
  display: flex;
  width: 49%;
  gap: 24px;
}

.Seccion__EventoOradores__orador__textoImagen {
  display: flex;
  flex-direction: row-reverse;
  width: 49%;
  gap: 24px;
}

.Seccion__EventoOradores__orador__imagen img {
  width: 250px;
  height: auto;
  border-radius: 50%;
}

.Seccion__EventoOradores__orador__informacion {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Seccion__EventoOradores__orador__informacion h3 {
  font-size: 18px;
  font-weight: 600;
}

.Seccion__EventoOradores__orador__informacion h4 {
  font-size: 14px;
  font-weight: 600;
  color: #094c8a;
}

.Seccion__EventoOradores__orador__informacion p {
  text-align: justify;
  line-height: 1.8;
}

.Seccion__EventoOradores__orador__redes{
  display: flex;
  gap: 32px;
}

.Seccion__EventoOradores__orador__redes a .icon {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 1450px) {
  .Seccion__EventoOradores__contenedor {
    width: 95%;
  }
}

@media screen and (max-width: 1150px) {
  .Seccion__EventoOradores__contenedor {
    gap: 32px;
  }

  .Seccion__EventoOradores__orador__imagenTexto {
    width: 75%;
  }

  .Seccion__EventoOradores__orador__textoImagen {
    width: 75%;
  }
}

@media screen and (max-width: 900px) {
  .Seccion__EventoOradores__orador__imagenTexto {
    width: 100%;
  }

  .Seccion__EventoOradores__orador__textoImagen {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .Seccion__EventoOradores__contenedor__oradorDerecha {
    justify-content: flex-start;
  }

  .Seccion__EventoOradores__orador__imagenTexto {
    flex-direction: column;
    align-items: center;
  }

  .Seccion__EventoOradores__orador__textoImagen {
    flex-direction: column;
    align-items: center;
  }
}
