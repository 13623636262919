.SeccionEvento {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
}

.SeccionEvento__contenedor {
  width: 80%;
  height: 350px;
  display: flex;
}

.SeccionEvento__imagen__lugarFecha {
  width: 60%;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.SeccionEvento__img {
  display: flex;
  justify-content: center;
  width: 95%;
  height: 70%;
}

.SeccionEvento__img img {
  width: auto;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #f3f1f1;
}

.SeccionEvento__fechaLugar {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SeccionEvento__fechaLugar p {
  font-family: "Open Sans";
  font-weight: 600;
  color: #094c8a;
  font-size: 14px;
  margin: 0;
}

.SeccionEvento__fechaLugar span {
  font-family: "Open Sans";
  color: #6c757d;
}

.SeccionEvento__textoIntroductorio__btn {
  width: 40%;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.SeccionEvento__textoIntroductorio__btn h2 {
  font-family: "Open Sans";
}

.SeccionEvento__textoIntroductorio__btn p {
  line-height: 1.6;
  text-align: justify;
}

.SeccionEvento__btn button {
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: #094c8a;
  background: #094c8a;
  color: white;
  float: inline-end;
  transition: all 0.3s ease-in-out;
}

.SeccionEvento__btn a {
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: #094c8a;
  background: #094c8a;
  color: white;
  float: inline-end;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.SeccionEvento__btn button:hover {
  transform: scale(1.06);
}

.SeccionEvento__btn__responsive {
  display: none;
}

.SeccionEvento__btn__responsive button {
  padding: 16px 34px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  border-radius: 4px;
  border-color: #094c8a;
  background: #094c8a;
  color: white;
  float: inline-end;
  transition: all 0.3s ease-in-out;
}

.SeccionEvento__btn__responsive button:hover {
  transform: scale(1.06);
}

@media screen and (max-width: 1400px) {
  .SeccionEvento__contenedor {
    width: 90%;
  }
}

@media screen and (max-width: 1250px) {
  .SeccionEvento__contenedor {
    flex-direction: column-reverse;
    height: auto;
    width: 90%;
  }

  .SeccionEvento__imagen__lugarFecha {
    width: 100%;
    padding-right: 0;
    justify-content: flex-start;
    align-items: center;
  }

  .SeccionEvento__img img {
    width: 90%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #f3f1f1;
  }

  .SeccionEvento__textoIntroductorio__btn {
    width: 100%;
    padding-left: 0;
  }

  .SeccionEvento__btn {
    display: none;
  }

  .SeccionEvento__btn__responsive {
    display: initial;
  }
}

/*Botones de eliminar y editar publicación*/

.SeccionEvento__botonesEditarEliminar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.SeccionEvento__botones__btnEditar {
  background-color: #3a802fa7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 5px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 10px;
  border: none;
}

.SeccionEvento__botones__btnEditar:hover {
  background-color: #38a926a7;
  color: #fff;
}

.SeccionEvento__botones__btnEditar svg {
  height: 20px;
}

.SeccionEvento__botones__btnEliminar {
  background-color: #cf1f1f;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 10px;
  border: none;
}

.SeccionEvento__botones__btnEliminar img {
  height: 20px;
}

.SeccionEvento__botones__btnEliminar:hover {
  background-color: #ec1414;
  color: #fff;
}

@media screen and (max-width: 1250px) {
  .SeccionEvento__botonesEditarEliminar {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
  }
}

.SeccionEvento__botones {
  display: flex;
  justify-content: space-between;
  font: 0/0 a;
  text-shadow: transparent;
  color: transparent;
  text-decoration: none;
}
