
.TituloSecundario{
    display: flex;
    justify-content: center;
    padding: 48px 0;
}

.TituloSecundario__contenedor{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.TituloSecundario__contenedor p{
    text-align: justify;
    line-height: 1.8;
}


.TituloSecundario__lineaDivisora {
    margin: 10px;
    width: 20%;
    height: 3px;
    background-color: #094c8a;
    border: 1px solid #094c8a;
}


@media screen and (max-width:1000px){
    .TituloSecundario__contenedor{
        width: 90%;
    }
}