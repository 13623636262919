.NavBarPrincipal {
  background: #094c8a;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  z-index: 1;
  position: fixed;
  margin-top:52px;;
}

.NavBarPrincipal__container {
  display: flex;
  height: 100%;
  width: 95%;
  justify-content: space-between;
  align-items: center;
}

.NavBarPrincipal__imagen img {
  width: 240px;
  height: 80px;
  cursor: pointer;
}

.NavBarPrincipal__menu {
  height: 100%;
  display: flex;
  align-items: center;
  
}

.NavBar__item__lista {
  display: flex;
  flex-direction: row;
  margin: 0;
    
  gap: 2em;
  list-style: none;
}

.NavBar__item__lista__elemento {
  padding: 10px 0;
}
.NavBar__item__lista__contenedor{
  display: flex;
  align-items: center;
}
.NavBar__item {
  text-decoration: none;
  color: #ffffff;
  position: relative;
}

.NavBar__item__flecha{
  padding: 0 0 5px 20px;
  cursor: pointer;
}

.NavBar__subitem__lista {
  position: absolute;
  margin-top: 10px;
  padding: 10px ;
  min-width: 200px;
  background: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1em;
  border-radius: 5px;
  display: none;
}

.NavBar__subitem__lista li a {
  text-decoration: none;
  color: #000000;
}

.NavBar__item__lista li:focus-within > .NavBar__subitem__lista,
.NavBar__item__lista li:hover > .NavBar__subitem__lista {
  display: initial;
}

/*Efectos de links*/

.NavBar__item::after {
  content: "";
  display: block;
  background: #fff;
  width: 90%;
  margin-top: 3px;
  height: 3px;
  clip-path: var(--clippy);
  transition: clip-path 0.5s;
}

.NavBar__item {
  --clippy: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.NavBar__item:hover {
  color: #fff;
  --clippy: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.NavBar__subitem::after {
  content: "";
  display: block;
  background: #000000;
  width: 90%;
  margin-top: 3px;
  height: 3px;
  clip-path: var(--clippy);
  transition: clip-path 0.5s;
}

.NavBar__subitem {
  --clippy: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.NavBar__subitem:hover {
  color: #000000;
  --clippy: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/*Despliegue de menu cuando la pantalla es menor a 1150px*/


.NavBarPrincipal__label{
    color: #fff;
}


.NavBarPrincipal__label,
.NavBarPrincipal__input {
  display: none;
}

/* #btnContacto{
  padding: 10px 15px;
  background: #fff;
      border-radius: 4px;
      color: #000000;
      border: none;
} */

@media screen and (max-width: 1150px) {
  .NavBarPrincipal__label {
    display: initial;
   cursor: pointer;
  }

  .NavBarPrincipal{
    position: relative;
    position: fixed;
  }

  .NavBarPrincipal__menu{
    position: absolute;

    height: auto;

    top: 100%;
    left: 0;
    right: 0;
    background: #094c8a;
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: none;
    color: #000000;
  }

  .NavBar__item::after ,.NavBar__subitem::after {
    width: 10%;
    background: #fff
  }

  .NavBar__item__lista {
    width: 100%;
    height: 100%;
    gap: 1em;
    flex-direction: column;
  }

  .NavBar__subitem__lista{
    position: relative;
    width: 100%;
    padding: 5px 0;
  }

  .NavBar__subitem__lista li a{
    color: #fff;
  }

  .NavBar__subitem:hover{
    color: #fff;
  }

  #NavBarPrincipal__checkbox:checked ~ .NavBarPrincipal__menu{
    display: initial;
  }

  .NavBarPrincipal__imagen img {
    width: 150px;
    height: 50px;
    cursor: pointer;
  }
}
