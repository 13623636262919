.SeccionBlog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
}

.SeccionBlog__informacion {
  width: 1160px;
  height: 150px;
  display: flex;
  justify-content: center;
}

.SeccionBlog__informacion div {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.SeccionBlog__informacion h2 {
  font-weight: 300;
  font-size: 32px;
  color: #212529;
  margin: 0 0 24px;
  line-height: 1.3;
}

.SeccionBlog__informacion hr {
  width: 100%;
  max-width: 70px;
  border-top: 4px solid #094c8a;
  border-radius: 6px;
  opacity: 1;
  margin: 32px 0;
}

.SeccionBlog__contenedor {
  width: 1160px;
  display: flex;
  justify-content: space-between;
}

/*Sección izquierda*/

.SeccionBlog__contenedor__izquierda {
  width: 63%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.SeccionBlog__contenedor__izquierda__imagen {
  width: 100%;
}

.SeccionBlog__contenedor__izquierda__imagen img {
  width: 100%;
  border-radius: 5px;
  transition: all .5s ease;
}


.SeccionBlog__contenedor__izquierda__imagen img:hover{
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);
  transform: translateY(-3%);
}

.SeccionBlog__contenedor__izquierda__temaFecha {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.SeccionBlog__contenedor__izquierda__temaFecha__tema{
  display: flex;
}

.SeccionBlog__contenedor__izquierda__temaFecha__tema a {
  text-decoration: none;
  font-weight: 700;
  color: #094c8a;
}



.underline-hover::after {
  content: "";
  display: block;
  background: #094c8a;
  width: 100%;
  margin-top: 3px;
  height: 3px;
  clip-path: var(--clippy);
  transition: clip-path 0.5s;
}

.underline-hover {
  --clippy: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.underline-hover:hover {
  color: #094c8a;
  --clippy: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.SeccionBlog__contenedor__izquierda__temaFecha span {
  color: #6c757d;
}

.SeccionBlog__contenedor__izquierda__descripcion {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.SeccionBlog__contenedor__izquierda__descripcion h3 a {
  text-decoration: none;
  font-weight: 700;
  color: #000000;
  transition: 0.3s;
}

.SeccionBlog__contenedor__izquierda__descripcion h3 a:hover {
  color: #094c8a;
}

/*Sección derecha*/

.SeccionBlog__contenedor__derecha {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.SeccionBlog__contenedor__derecha__bloque {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  gap: 16px;
}

.SeccionBlog__contenedor__derecha__imagen {
  width: 100%;
}

.SeccionBlog__contenedor__derecha__imagen img {
  width: 100%;
  border-radius: 5px;
  transition: all .5s ease;
}

.SeccionBlog__contenedor__derecha__imagen img:hover{
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);
  transform: translateY(-3%);
}

.SeccionBlog__contenedor__derecha__temaFecha {
  display: flex;
  flex-direction: column;
}

.SeccionBlog__contenedor__derecha__temaFecha__tema{
  display: flex;
}

.SeccionBlog__contenedor__derecha__temaFecha__tema a {
  text-decoration: none;
  font-weight: 700;
  color: #094c8a;
}

.SeccionBlog__contenedor__derecha__temaFecha span {
  color: #6c757d;
}



.SeccionBlog__contenedor__derecha__titulo{
  display: flex;
}

.SeccionBlog__contenedor__derecha__titulo h3 {
  font-size: 20px;
  font-weight: 700;
  
}

.SeccionBlog__contenedor__derecha__titulo h3 a {
  text-decoration: none;
  color: #000000;
  transition: 0.3s;
}

.SeccionBlog__contenedor__derecha__titulo h3 a:hover{
  color: #094c8a;
}

/*Sección de Boton*/

.SeccionBlog__boton {
  width: 1160px;
  display: flex;
  justify-content: center;
  color: #fff;
}

.SeccionBlog__boton a {
  font-size: 14px;
  font-weight: 700;
  padding: 16px 34px;
  line-height: 1;
  border-radius: 4px;
  border: none;
  background: #094c8a;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}



@media screen and (max-width:1160px) {
  .SeccionBlog__informacion, .SeccionBlog__contenedor, .SeccionBlog__boton{
    width: 98%;
  }
}

@media screen and (max-width:750px) {
  .SeccionBlog__contenedor{
    flex-direction: column;
    align-items: center;
  }

  .SeccionBlog__contenedor__izquierda{
    width: 98%;
    margin-bottom: 64px;
  }

  .SeccionBlog__contenedor__derecha{
    width: 98%;
  }
}